* {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
.page {
  background: white;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: 24px;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  max-width: 21cm;
  height: 29.7cm;
  padding-left: 2.5cm;
  padding-right: 2.5cm;
  border-radius: 4px;
  font-size: 14px;
  .page-header,
  .page-footer {
    min-height: 2.5cm;
  }
  .page-header {
    padding-top: 48px;
    padding-bottom: 28px;
  }
  .page-footer {
    padding-bottom: 48px;
    padding-top: 28px;
  }
  .page-body {
    flex: 1 1 auto;
    min-height: 0;
    .editable {
      cursor: pointer;
    }
  }
}
span.editable.valid {
  border-bottom: 1px dashed green;
}
span.editable.invalid {
  border-bottom: 1px dashed orangered;
  font-weight: 500;
}
div.editable {
  border-radius: 4px;
}
div.editable.valid {
  border: 1px dashed green;
}
div.editable.invalid {
  border: 1px dashed orangered;
  font-weight: 500;
  min-height: 16px;
}
h1,
h2,
h3,
h4,
h5 {
  font-weight: 100;
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.6em;
}

h3 {
  font-size: 1.2em;
}
/* CSS overrides to fix react-wysiwyg-editor margin issues when justifying text */
.public-DraftStyleDefault-block > div {
  margin: 0px !important;
  display: block !important;
}
.public-DraftStyleDefault-ltr {
  text-align: inherit !important;
}
.rdw-left-aligned-block > div,
.rdw-center-aligned-block > div,
.rdw-right-aligned-block > div {
  display: block !important;
}
